import React, { useState } from 'react'
import { logOut } from '../../features/auth/authSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { changeBackgroundPosition } from "../tools/backgroundPositionSlice"

import '../../scss/customer-nav.scss'

import { Dropdown, Button } from 'react-bootstrap'
import { FaSignOutAlt, FaUserAlt } from 'react-icons/fa'

const CustomerNav = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogout = () => {
    dispatch(changeBackgroundPosition("-150%"));
    dispatch(logOut());
    navigate('/');
  };

  const content = (
    <div className='customer-nav'>
      <Dropdown drop='down' open={dropdownOpen} toggle={toggle}>
        <Dropdown.Toggle 
            id='logout-dropdown'
            className="customer-nav-btn"
            as={Button}>
            <FaUserAlt className="mr-2" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={handleLogout}><FaSignOutAlt /> Déconnexion</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )

  return content
}
export default CustomerNav