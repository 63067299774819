import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials, logOut } from '../../features/auth/authSlice'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState, endpoint }) => {
    const access = getState().auth.access

    if (access) {
      headers.set("authorization", `Bearer ${access}`)
    }

    return headers
  }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  //EN ATTENTE API
  return true;
  let result = await baseQuery(args, api, extraOptions)

  if (result?.error?.status === 401) {
    const refresh = api.getState().auth.refresh
    localStorage.setItem("tokens",  JSON.stringify({ refresh: refresh }));

    const response = await fetch(process.env.REACT_APP_API_URL + '/token/refresh/', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ refresh: refresh })
    })

    const data = await response.json()

    if (response.ok) {
      const email = api.getState().auth.email

      // store the new token
      api.dispatch(setCredentials({ ...data, email, refresh }))
      
      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(logOut())
    }
  }

  if (result.data.refresh) {
    const refresh = result.data.refresh
    localStorage.setItem("tokens",  JSON.stringify({ refresh: refresh }));
  }

  return result
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({})
})
