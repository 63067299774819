import React, { useState, useEffect } from 'react';
import Header from '../features/Header';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import '../scss/pages/categoryPage.scss';
import { useDispatch } from 'react-redux';
import HandleClickToDashboard from '../features/tools/HandleClickToDashboard';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from "react-redux";

const CategoryPage = () => {
  const apiUrl = useSelector(state => state.apiUrl);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { category } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const [show, setShow] = useState(false);
  const [processes, setProcesses] = useState([]);


  function navigateToProcess(process) {
    navigate("/processes/" + process.id, { state: { prevLocation: location, process: process } })
  }

  const handleSearchChange = e => {
    setSearchValue(e.target.value);
  };

  const filteredItems = processes.filter(item => {
    return item.title.toLowerCase().includes(searchValue.toLowerCase());
  });

  useEffect(() => {
    const loadProcesses = () => {
      fetch(`${apiUrl}/processes?category=${category}`)
        .then(response => response.json())
        .then(data => {
          setProcesses(data)
        })
        .catch(error => console.error('Erreur lors de la récupération des processes :', error));
      }

      loadProcesses();
  }, [])


  return (
    <div className='category-wrapper'>
       <HandleClickToDashboard dispatch={dispatch} navigate={navigate} setShow={setShow} />

       <div className='category-unique'>
         <Header headerTitle={'Liste des tutos / process'} backArrow={true} closeIcon={true}/>

         <div className='category-unique-content'>
           <div className='row'>
             <div className='col-6'>
               <div className='category-unique-content-title'>
                 Catégorie {category}
               </div>
                {filteredItems.map((process, i) => {
                  if (i % 2 === 0) {
                    return(
                      <div key={i} className='category-unique-content-post'>
                      {process.image ? <img className='category-unique-content-post-image' src={process.image} /> : ''}
                      <h2 className='category-unique-content-post-title'>- {process.title}</h2>
                      <p className='category-unique-content-post-content'>{process.content}</p>
                      <button className='category-unique-content-post-button' onClick={() => navigateToProcess(process)}>En savoir +</button>
                    </div>)
                  }
                })
                }
             </div>
             <div className='col-6'>
               <div className='category-unique-content-search'>
                 <input
                   type="text"
                   value={searchValue}
                   onChange={handleSearchChange}
                   placeholder="Rechercher"
                 />
                 <div className='category-unique-content-search-icon'>
                   <FontAwesomeIcon size="3x" icon={faSearch} />
                 </div>
               </div>
               {filteredItems.map((process, i) => {
                  if (i % 2 !== 0) {
                    return(
                      <div key={i} className='category-unique-content-post'>
                      {process.image ? <img className='category-unique-content-post-image' src={process.image} /> : ''}
                      <h2 className='category-unique-content-post-title'>- {process.title}</h2>
                      <p className='category-unique-content-post-content'>{process.content}</p>
                      <button className='category-unique-content-post-button' onClick={() => navigateToProcess(process)}>En savoir +</button>
                    </div>)
                  }
                })
              }
             </div>
           </div>

         </div>
       </div>
     </div>
   )
 }

 export default CategoryPage