import React, { useState, useEffect } from 'react';
import '../scss/pages/tasksListPage.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeBackgroundPosition } from "../features/tools/backgroundPositionSlice";
import Header from '../features/Header';
import Table from "../features/DataTable";
import { FaPause, FaPlay, FaRegEye, FaStop, FaStopCircle } from 'react-icons/fa';
import HandleClickToDashboard from '../features/tools/HandleClickToDashboard';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

const TasksListPage = () => {
  const apiUrl = useSelector(state => state.apiUrl);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const prevSlug = location.state && location.state.prevLocation && location.state.prevLocation.pathname;

  //Utile pour l'effet de transition
  const [show, setShow] = useState(false);

  const [tasks, setTasks] = useState({});

  const clickhandler = (id) => {
    navigate(`/task/${id}`, { state: { prevLocation: location } })
  }

  useEffect(() => {
    dispatch(changeBackgroundPosition("80vw"));
    setShow(true);
  }, [])

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  }

  useEffect(() => {
    const loadTasks = () => {
      fetch(`${apiUrl}/tasks`)
        .then(response => response.json())
        .then(datas => {
          setTasks(datas)
        })
        .catch(error => console.error('Erreur lors de la récupération des tâches :', error));
    }

    loadTasks();
  }, [])

  const columns = [
    {
      name: "Type",
      selector: "type",
      sortable: true
    },
    {
      name: "Titre",
      selector: "title",
      sortable: true
    },
    {
      name: "Description",
      selector: "description",
      sortable: true
    },
    {
      name: "Date limite",
      selector: "deadline",
      sortable: true,
      cell: row => {
        /* Affichage de la date */
        let date = new Date(row.deadline);
        let options = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false
        };

        return date.toLocaleString("fr-FR", options);
      }
    },
    {
      name: "Statut",
      selector: "status",
      sortable: true,
      width: "300px",
      button: true,
      cell: row =>
        row.showButtons ? (
          <TableRow key={row.id} data={row} />
        ) : null
    }
  ];

  function TableRow({ key, data }) {
    const [isRunning, setIsRunning] = useState(data.status == 'in_progress' ? true : false);

    const currDate = new Date();
    const dateInProgress = data.start_date_track ? new Date(data.start_date_track) : null;
    const differenceInMilliseconds = dateInProgress ? currDate.getTime() - dateInProgress.getTime() : 0;
    const [differenceInSeconds, setDifferenceInSeconds] = useState(differenceInMilliseconds != 0 ? Math.floor(differenceInMilliseconds / 1000) : data.time_track)
    console.log(differenceInSeconds)

    const handlePlayTimer = () => {
      // Si ca n'a pas démarré et que la date de début n'est pas mise
      // Alors on mets le statut "in_progress" et on initialise la date de début
      if (!isRunning && data.start_date_track == null) {
        const currentDate = currDate;
        data.status = 'in_progress';
        data.start_date_track = currentDate;

        fetch(`${apiUrl}/tasks/${data.id}`, {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' }
        });


        // Si c'est en cours et que le statut est "in_progress"
        // Le clic stockera le timer et le mettra en pause
      } else if (isRunning && data.status == 'in_progress') {
        console.log('PAUSE')
        data.time_track = differenceInSeconds;
        data.status = 'stopped';
        // data.start_date_track = null;

        fetch(`${apiUrl}/tasks/${data.id}`, {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' }
        });

        // Sinon, c'est qu'il n'est pas relancé, donc on le remets en in_progress
      } else if (!isRunning && data.status == 'stopped') {
        console.log('RELANCE')
        const currentDate = new Date();
        data.status = 'in_progress';
        data.time_track = differenceInSeconds;
        // data.start_date_track = currentDate;

        fetch(`${apiUrl}/tasks/${data.id}`, {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' }
        });

      }

      setIsRunning(!isRunning);
    }

    useEffect(() => {
      let intervalId;

      if (isRunning) {
        intervalId = setInterval(() => {
          setDifferenceInSeconds(differenceInSeconds => differenceInSeconds + 1)
        }, 1000);
      } else if (!isRunning && differenceInSeconds !== 0) {
        clearInterval(intervalId);
      }

      return () => clearInterval(intervalId);
    }, [isRunning, differenceInSeconds])

    const handleCloseTask = () => {
      console.log(data.id)

      confirmAlert({
        title: '',
        message: 'Êtes-vous sûr de vouloir mettre fin à la tâche en cours ?',
        buttons: [
          {
            label: 'Oui',
            onClick: async () => {
              try {
                data.status = 'ended';

                const response = await fetch(`${apiUrl}/tasks/${data.id}`, {
                  method: 'PUT',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(data)
                });

                setTasks(tasks.filter(element => element.id !== data.id));

                toast.success("Tâche clôturée", {
                  position: toast.POSITION.TOP_RIGHT
                });
              } catch (error) {
                console.error('Error updating customer data:', error);
                toast.error("Erreur lors de la mise à jour de la tâche", {
                  position: toast.POSITION.TOP_RIGHT
                });
              }
            }
          },
          {
            label: 'Non',
            onClick: () => { }
          }
        ]
      });
    }

    return (
      <>
        <button onClick={() => handlePlayTimer()}>
          {data.status == 'in_progress' ? <FaPause /> : <FaPlay />}
        </button>
        <button onClick={() => handleCloseTask()}><FaStopCircle /></button>
        <button onClick={() => clickhandler(data.id)} className='task-timer'>
          <span className={data.status == 'in_progress' ? 'task-timer-bulle' : 'task-timer-bulle pause'}></span>{formatTime(differenceInSeconds)}
        </button>
        <button onClick={() => clickhandler(data.id)}><FaRegEye /></button>
      </>
    );
  }

  return (
    <div className='tasks-wrapper'>
      <div className='tasksList'
        style={{
          marginLeft: show ? '0' : '-80%',
          transition: prevSlug === "/dashboard" && 'all 2s'
        }}
      >
        <Header headerTitle='Liste des tâches' />
        <div className='legends-task'>
          <p className='legends-task-text'><span className='legends-task-timer-bulle'></span> Tâche en cours</p>
          <p className='legends-task-text'><span className='legends-task-timer-bulle-pause'></span> Tâche en pause</p>
        </div>
        <Table data={tasks} columns={columns} />
      </div>
      <HandleClickToDashboard dispatch={dispatch} navigate={navigate} setShow={setShow} />
    </div>
  )
}

export default TasksListPage