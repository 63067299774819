import React, { useEffect, useState } from 'react';
import Header from '../features/Header';
import '../scss/pages/customerFormPage.scss';
import { changeBackgroundPosition } from '../features/tools/backgroundPositionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

const DepotEditPage = () => {
  const apiUrl = useSelector(state => state.apiUrl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [show, setShow] = useState(false);
  const [customerId, setCustomerId] = useState(null);

  const [formData, setFormData] = useState({});

  useEffect(() => {
    fetch(`${apiUrl}/depots/${params.id}`)
      .then(response => response.json())
      .then(data => {
        setFormData(data);
      })
      .catch(error => console.error('Error fetching depot data:', error));

    dispatch(changeBackgroundPosition("100vw"));
    setShow(true);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    confirmAlert({
      title: '',
      message: 'Êtes-vous sûr de vouloir modifier les données de ce dépot ?',
      buttons: [
        {
          label: 'Oui',
          onClick: async () => {
            try {
              const response = await fetch(`${apiUrl}/depots/${params.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
              });
              const data = await response.json();
              toast.success("Dépot mis à jour avec succès!", {
                position: toast.POSITION.TOP_RIGHT
              });
              navigate(`/depot/${params.id}`, { state: { prevLocation: location } });
            } catch (error) {
              console.error('Error updating depot data:', error);
              toast.error("Erreur lors de la mise à jour du dépot", {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          }
        },
        {
          label: 'Non',
          onClick: () => { }
        }
      ]
    });
  }

  useEffect(() => {
    if(location.state && location.state.id_customer) {
      setCustomerId(parseInt(location.state.id_customer));
    }

    dispatch(changeBackgroundPosition("100vw"));
    setShow(true);
  }, [])

  return (
    <div>
      <Header headerTitle={`Modification d'un dépot`} backArrow={true} closeIcon={true} />
      <div className='depotpage-wrapper'>
        <form onSubmit={handleSubmit}>
          <div className='row lgbt-row'>
          </div>
          <div className='row lgbt-row lgbt-bg-grey'>
            <div className='offset-3 col-4 d-flex flex-column justify-content-start'>
              <label className='lgbt-label'>Date de réception</label><br />
              <input type='datetime-local' className='lgbt-datelocal' name='deposited_at' value={formData.deposited_at} onChange={handleChange} />
            </div>
          </div>
          <div className='row lgbt-row lgbt-bg-grey'>
            <div className='offset-3 col-6 d-flex flex-column justify-content-start'>
              <label className='lgbt-label'>Matériel</label><br/>
              <textarea className='lgbt-textarea' name='description' value={formData.description && formData.description} onChange={handleChange} rows='7'/>
            </div>
          </div>
          <div className='row lgbt-row'>
            <div className='col-12 d-flex justify-content-center'>
              <button className='depotpage-button'>Modifier</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default DepotEditPage;
