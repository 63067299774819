import React, { useMemo, useState } from "react";

import DataTable from "react-data-table-component";
import FilterComponent from "./FilterComponent";
import '../scss/features/dataTable.scss';

const NoData = () => (
  <div>Aucune tâche à afficher.</div>
);

const Table = props => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const filteredItems = props.data.length > 0 ? props.data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  ) : [];

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const paginationComponentOptions = {
    noRowsPerPage: false,
    rowsPerPageText: 'Nombre de lignes',
    rangeSeparatorText: 'sur'
  };

  return (
    <DataTable
      // title=""
      columns={props.columns}
      data={filteredItems}
      defaultSortField="name"
      striped
      pagination
      subHeader
      // subHeaderComponent={subHeaderComponent}
      paginationPerPage={10}
      paginationComponentOptions={paginationComponentOptions}
      noDataComponent={<NoData />}
    />
  );
};

export default Table;
