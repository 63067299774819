import React, { useEffect, useState } from 'react';
import Header from '../features/Header';
import '../scss/pages/customerFormPage.scss';
import { changeBackgroundPosition } from '../features/tools/backgroundPositionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaRegEdit } from 'react-icons/fa';

const DepotPage = () => {
  const apiUrl = useSelector(state => state.apiUrl);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [depot, setDepot] = useState();
  const [customer, setCustomer] = useState();
  const [fetchedDepot, setFetchedDepot] = useState();

  const [friendlyDepositedAt, setFriendlyDepositedAt] = useState();

  useEffect(() => {
    dispatch(changeBackgroundPosition("100vw"));
    setShow(true);
  }, [])

  useEffect(() => {
    const loadDepot = () => {
      fetch(`${apiUrl}/depots/${params.id}`)
        .then(response => response.json())
        .then(data => {
          setDepot(data)
          setFetchedDepot(!fetchedDepot);
        })
        .catch(error => console.error('Erreur lors de la récupération des données du dépot :', error));
    }

    loadDepot();
  }, [])

  useEffect(() => {
    if (depot) {
      const loadCustomer = () => {
        fetch(`${apiUrl}/customers/${depot.id_customer}`)
          .then(response => response.json())
          .then(data => {
            setCustomer(data)
          })
          .catch(error => console.error('Erreur lors de la récupération des données du client :', error));
      }

      loadCustomer();

      /* Affichage de la date */
      let date = new Date(depot.deposited_at);
      let options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
      };

      setFriendlyDepositedAt(date.toLocaleString("fr-FR", options));

    }
  }, [fetchedDepot])

  const handleClickToCustomer = (id_customer) => {
    navigate(`/customer/${id_customer}`, { state: { prevLocation: location } });
  }

  const handleClickUpdate = () => {
    navigate(`/depot/edit/${params.id}`, { state: { prevLocation: location } });
  }

  return (
    <div>
      {depot &&
        <>
          <Header headerTitle={`Dépot ${depot.id}`} backArrow={true} closeIcon={true} />
          <div className='depotpage-wrapper'>
            <div className='row'>
              <div className='col-8'>
                <div className='row lgbt-row'>
                </div>
                <div className='row lgbt-row lgbt-bg-grey'>
                  <div className='offset-3 col-4 d-flex flex-column justify-content-start'>
                    <label className='lgbt-label'>Date de réception</label><br /><br />
                    <span className='lgbt-text'>
                      {friendlyDepositedAt ? friendlyDepositedAt : '-'}
                    </span>
                  </div>
                </div>
                <div className='row lgbt-row lgbt-bg-grey'>
                  <div className='offset-3 col-6 d-flex flex-column justify-content-start'>
                    <label className='lgbt-label'>Matériel</label><br /><br />
                    <span className='lgbt-longtext' dangerouslySetInnerHTML={{ __html: depot.description.replace(/\n/g, '<br />')}} />
                  </div>
                </div>
              </div>
              {customer &&
                <>
                  <div className='col-4 task-customercard'>
                    <span className='task-customercard-title'>{(customer.gender == "m" || customer.gender == "M") ? 'M.' : 'MME'} {customer.lastname ? customer.lastname : ''} {customer.firstname ? customer.firstname : ''}</span>
                    {customer.phone_number && <span className='task-customercard-label'>{customer.phone_number}</span>}
                    {customer.email && <span className='task-customercard-label'>{customer.email}</span>}
                    <span onClick={() => handleClickToCustomer(customer.id)} className='task-customercard-label'>Voir la fiche client</span>
                  </div>
                </>
              }
              <div className='row lgbt-row'>
                <div className="offset-2 col-1" onClick={handleClickUpdate}>
                  <span className='lgbt-action-icon'><FaRegEdit /></span>
                  <span className='lgbt-action-label'>Modifier</span>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default DepotPage;
