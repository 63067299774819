import React, { useEffect } from 'react';
import '../scss/pages/dashboardPage.scss';
import CustomerNav from '../features/users/CustomerNav';
import { FaPlus } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { changeBackgroundPosition } from "../features/tools/backgroundPositionSlice";
import { useNavigate, useLocation } from 'react-router-dom';

function DashboardPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClickTask = () => {
    dispatch(changeBackgroundPosition("80vw"));

    navigate('/tasks', { state: { prevLocation: location } });
  }

  const handleClickCustomerSearch = () => {
    dispatch(changeBackgroundPosition("100vw"));

    navigate('/customer-search', { state: { prevLocation: location } });
  }

  const handleClickProcess = () => {
    dispatch(changeBackgroundPosition("-50vw"));

    navigate('/processes', { state: { prevLocation: location } });
  }

  useEffect(() => {
    dispatch(changeBackgroundPosition("60%"));
  }, [])

  return (
    <>
      <CustomerNav></CustomerNav>
      <div className="dashboardpage-wrapper">
        <span className="dashboardpage-col dashboardpage-left" onClick={() => handleClickTask()}>
          <h2 className="dashboardpage-left-text">
            Voir les tâches
          </h2>
        </span>
        <div className="dashboardpage-col dashboardpage-center" onClick={() => handleClickCustomerSearch()}>
          <h2 className="dashboardpage-center-text">
            Trouver / créer un client
          </h2>
        </div>
        <div className="dashboardpage-col dashboardpage-right" onClick={() => handleClickProcess()}>
          <h2 className="dashboardpage-right-text">
            Tutos / Blog
          </h2>
        </div>
      </div>
    </>
  )
}

export default DashboardPage;
