import React, { useEffect, useState } from 'react';
import Header from '../features/Header';
import '../scss/pages/customerFormPage.scss';
import { changeBackgroundPosition } from '../features/tools/backgroundPositionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const DepotCreatePage = () => {
  const apiUrl = useSelector(state => state.apiUrl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [formData, setFormData] = useState({
    deposited_at: '',
    status: '',
    description: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let depots = {
      id_customer: customerId,
      id_diag: null,
      id_truck: 1,
      deposited_at: formData.deposited_at,
      collected_at: null,
      status: formData.status,
      description: formData.description,
    };

    fetch(`${apiUrl}/depots`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(depots)
    })
      .then(response => response.json())
      .then(data => {
        const { id } = data;

        let today = new Date();
        let deadline = today.setDate(today.getDate() + 7);

        let task = {
          id_user: 1,
          id_diag: null,
          id_customer: customerId,
          id_question: 1,
          id_prestation: null,
          id_depot: null,
          title: `Diagnostic du dépot ${id}`,
          description: "Analyse Complète du matériel",
          type: 'diag',
          status: 'à faire',
          deadline: deadline,
          created_at: today,
          updated_at: null,
          showButtons: true,
          "start_date_track": null,
          "time_track": 0,
        };

        fetch(`${apiUrl}/tasks`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(task)
        })

        toast.success("Dépot créé avec succès !", {
          position: toast.POSITION.TOP_RIGHT
        });

        navigate(`/depot/${id}`, { state: { prevLocation: location } });
      })
      .catch(error => console.error('Erreur lors de l ajout :', error));
  }

  useEffect(() => {
    if(location.state && location.state.id_customer) {
      setCustomerId(parseInt(location.state.id_customer));
    }

    dispatch(changeBackgroundPosition("100vw"));
    setShow(true);
  }, [])

  return (
    <div>
      <Header headerTitle={`Création d'un dépot`} backArrow={true} closeIcon={true} />
      <div className='depotpage-wrapper'>
        <form onSubmit={handleSubmit}>
          <div className='row lgbt-row'>
          </div>
          <div className='row lgbt-row lgbt-bg-grey'>
            <div className='offset-3 col-4 d-flex flex-column justify-content-start'>
              <label className='lgbt-label'>Date de réception</label><br /><br />
              <input type='datetime-local' className='lgbt-datelocal' name='deposited_at' value={formData.deposited_at} onChange={handleChange} />
            </div>
          </div>
          <div className='row lgbt-row lgbt-bg-grey'>
            <div className='offset-3 col-6 d-flex flex-column justify-content-start'>
              <label className='lgbt-label'>Matériel</label>
              <textarea className='lgbt-textarea' name='description' value={formData.description} onChange={handleChange} rows='7'/>
            </div>
          </div>
          <div className='row lgbt-row'>
            <div className='col-12 d-flex justify-content-center'>
              <button className='depotpage-button'>Créer</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default DepotCreatePage;
