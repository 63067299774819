import React from 'react';
import '../scss/features/backgroundTruck.scss';
import { changeBackgroundPosition } from "./tools/backgroundPositionSlice";
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  backgroundPosition: state.backgroundPosition,
});

const mapDispatchToProps = dispatch => ({
  changeBackgroundPosition: position => {
    dispatch(changeBackgroundPosition(position))
  }
});

class BackgroundTruck extends React.Component {
  render() {
    return (
      <div className="background-truck" style={{ backgroundPosition: this.props.backgroundPosition }}>
        {this.props.children}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundTruck);
