import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { setCredentials } from './authSlice';
import { useLoginMutation } from './authApiSlice';

import usePersist from '../../hooks/usePersist';

import '../../scss/forms/loginForm.scss';

import { Link } from 'react-router-dom';

import { changeBackgroundPosition } from "../tools/backgroundPositionSlice"

const Login = () => {
    const emailRef = useRef()
    const errRef = useRef()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [persist, setPersist] = usePersist()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [login, { isLoading }] = useLoginMutation()

    useEffect(() => {
        emailRef.current.focus()
    }, [])

    useEffect(() => {
        setErrMsg('')
    }, [email, password])

    const handleSubmit = async (e) => {
        e.preventDefault()

        //EN ATTENTE API
        if (
            (email == "thomas.focqueu@mozartsduweb.com" || email == "baptiste.rossignol@mozartsduweb.com") || email == "ceo@informatruck.fr"
            &&
            (password == "password")
        ) {
            dispatch(changeBackgroundPosition("50%"));
            setEmail('')
            setPassword('')
            navigate('/dashboard')
        } else {
            setErrMsg('Identifiant ou mot de passe incorrect');
        }

        // try {
        //     const emailData = await login({ email, password }).unwrap()
        //     dispatch(setCredentials({ ...emailData, email }))
        //     dispatch(changeBackgroundPosition("50%"));
        //     setEmail('')
        //     setPassword('')
        //     navigate('/dashboard')
        // } catch (err) {
        //     console.log(err)
        //     if (!err?.status) {
        //         setErrMsg('No Server Response');
        //     } else if (err.status === 400) {
        //         setErrMsg('Missing Emailname or Password');
        //     } else if (err.status === 401) {
        //         setErrMsg('Unauthorized');
        //     } else {
        //         setErrMsg('Login Failed');
        //     }
        //     errRef.current.focus();
        // }
    }

    const handleEmailInput = (e) => setEmail(e.target.value)
    const handlePasswordInput = (e) => setPassword(e.target.value)
    const handleToggle = () => setPersist(prev => !prev)

    const content = isLoading ? '' : (
        <div className='login-form'>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <input
                        placeholder="IDENTIFIANT*"
                        type="text"
                        id="email"
                        className="login-form-text form-control"
                        ref={emailRef}
                        value={email}
                        onChange={handleEmailInput}
                        autoComplete="off"
                        required
                    />
                </div>

                <div className="form-group">
                    <input
                        placeholder="MOT DE PASSE*"
                        type="password"
                        id="password"
                        className="login-form-password form-control"
                        onChange={handlePasswordInput}
                        value={password}
                        required
                    />
                </div>

                <div className="form-group mb-3">
                    <button type="submit" className="login-form-submit">Connexion</button>
                </div>

                <div className="d-flex justify-content-between">
                    <div className="form-check">
                        <input className="form-check-input"
                            type="checkbox"
                            id="persist"
                            onChange={handleToggle}
                            checked={persist} />
                        <label className="form-check-label login-form-remember-me" htmlFor="flexCheckDefault">
                            Se souvenir de moi ?
                        </label>
                    </div>
                    <small><Link className="login-form-lost-password" to="/mot-de-passe-oublie/">Mot de passe oublié ?</Link></small>
                </div>
            </form>
        </div>
    )

    return content
}
export default Login