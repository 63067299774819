import React, { useEffect, useState } from 'react';
import Header from '../features/Header';
import '../scss/pages/customerFormPage.scss';
import { changeBackgroundPosition } from '../features/tools/backgroundPositionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const CustomerCreatePage = () => {
  const apiUrl = useSelector(state => state.apiUrl);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    lastname: '',
    firstname: '',
    gender: '',
    age: '',
    address1: '',
    postalcode: '',
    city: '',
    address2: '',
    phone: '',
    email: '',
    comment: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let customers = {
      invoices: [],
      quotes: [],
      tasks: [],
      phone_number: formData.phone,
      address1: formData.address1,
      address2: formData.address2,
      city: formData.city,
      postalcode: formData.postalcode,
      email: formData.email,
      firstname: formData.firstname,
      lastname: formData.lastname,
      gender: formData.gender,
      comments: formData.comments,
      age: formData.age,
    };

    fetch(`${apiUrl}/customers`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(customers)
    })
      .then(response => response.json())
      .then(data => {
        const { id } = data;

        toast.success("Client créé avec succès !", {
          position: toast.POSITION.TOP_RIGHT
        });

        navigate(`/customer/${id}`, { state: { prevLocation: location } });
      })
      .catch(error => console.error('Erreur lors de la mise à jour du fichier customers.json :', error));
  }

  useEffect(() => {
    dispatch(changeBackgroundPosition("100vw"));
    setShow(true);
  }, [])

  return (
    <div>
      <Header headerTitle={`Création d'un client`} backArrow={true} closeIcon={true} />
      <div className='customerpage-wrapper'>
        <form onSubmit={handleSubmit}>
          <div className='row lgbt-row'>
            <div className='col-12'>
              <span className='lgbt-title'>Client</span>
            </div>
          </div>
          <div className='row lgbt-row lgbt-bg-grey'>
            <div className='col-3'>
              <label className='lgbt-label'>Nom</label>
              <input type='text' className='lgbt-textupd' name='lastname' value={formData.lastname} onChange={handleChange} />
            </div>
            <div className='col-3'>
              <label className='lgbt-label'>Prénom</label>
              <input type='text' className='lgbt-textupd' name='firstname' value={formData.firstname} onChange={handleChange} />
            </div>
          </div>
          <div className='row lgbt-row'>
            <div className='col-3'>
              <label className='lgbt-label'>Sexe</label>
              <input type='text' className='lgbt-textupd' name='gender' value={formData.gender} onChange={handleChange} />
            </div>
            <div className='col-3'>
              <label className='lgbt-label'>Âge</label>
              <input type='text' className='lgbt-textupd' name='age' value={formData.age} onChange={handleChange} />
            </div>
          </div>
          <div className='row lgbt-row lgbt-bg-grey'>
            <div className='col-6'>
              <label className='lgbt-label'>Adresse 1</label>
              <input type='text' className='lgbt-textupd' name='address1' value={formData.address1} onChange={handleChange} />
            </div>
            <div className='col-3'>
              <label className='lgbt-label'>CP</label>
              <input type='text' className='lgbt-textupd' name='postalcode' value={formData.postalcode} onChange={handleChange} />
            </div>
            <div className='col-3'>
              <label className='lgbt-label'>Ville</label>
              <input type='text' className='lgbt-textupd' name='city' value={formData.city} onChange={handleChange} />
            </div>
          </div>
          <div className='row lgbt-row'>
            <div className='col-6'>
              <label className='lgbt-label'>Adresse 2</label>
              <input type='text' className='lgbt-textupd' name='address2' value={formData.address2} onChange={handleChange} />
            </div>
          </div>
          <div className='row lgbt-row lgbt-bg-grey'>
            <div className='col-3'>
              <label className='lgbt-label'>Téléphone</label>
              <input type='text' className='lgbt-textupd' name='phone' value={formData.phone} onChange={handleChange} />
            </div>
            <div className='col-6'>
              <label className='lgbt-label'>E-mail</label>
              <input type='email' className='lgbt-textupd' name='email' value={formData.email} onChange={handleChange} />
            </div>
          </div>
          <div className='row lgbt-row'>
            <div className='col-12'>
              <label className='lgbt-label'>Commentaire</label>
              <textarea className='lgbt-textupd' name='comments' value={formData.comments} onChange={handleChange} />
            </div>
          </div>
          <div className='row lgbt-row'>
            <div className='col-12 d-flex justify-content-center'>
              <button className='customerpage-button'>Créer</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CustomerCreatePage;
