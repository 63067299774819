import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: 'auth',
  initialState: { email: null, access: null, refresh: null },
  reducers: {
    setCredentials: (state, action) => {
      const {email, access, refresh } = action.payload
      state.email = email
      state.access = access
      state.refresh = refresh
    },
    logOut: (state, action) => {
      state.email = null
      state.access = null
      state.refresh = null
      localStorage.removeItem("tokens")
      localStorage.removeItem("bgposition")
      localStorage.removeItem("persist")
    }
  },
})

export const { setCredentials, logOut} = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.email
export const selectAccessToken = (state) => state.auth.access