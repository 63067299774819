import React, { useEffect, useState } from 'react';
import Header from '../features/Header';
import '../scss/pages/customerFormPage.scss';
import { changeBackgroundPosition } from '../features/tools/backgroundPositionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

const CustomerEditPage = () => {
  const apiUrl = useSelector(state => state.apiUrl);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    fetch(`${apiUrl}/customers/${params.id}`)
      .then(response => response.json())
      .then(data => {
        setFormData(data);
      })
      .catch(error => console.error('Error fetching customer data:', error));

    dispatch(changeBackgroundPosition("100vw"));
    setShow(true);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    confirmAlert({
      title: '',
      message: 'Êtes-vous sûr de vouloir modifier les données de ce client ?',
      buttons: [
        {
          label: 'Oui',
          onClick: async () => {
            try {
              const response = await fetch(`${apiUrl}/customers/${params.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
              });
              const data = await response.json();
              toast.success("Client mis à jour avec succès!", {
                position: toast.POSITION.TOP_RIGHT
              });
              setTimeout(() => {
                navigate(`/customer/${params.id}`, { state: { prevLocation: location } });
              }, 1000);
            } catch (error) {
              console.error('Error updating customer data:', error);
              toast.error("Erreur lors de la mise à jour du client", {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          }
        },
        {
          label: 'Non',
          onClick: () => { }
        }
      ]
    });
  };

  useEffect(() => {
    dispatch(changeBackgroundPosition("100vw"));
    setShow(true);
  }, [])

  return (
    <div>
      <Header headerTitle={`Modification d'un client`} backArrow={true} closeIcon={true} />
      <div className='customerpage-wrapper'>
        <form onSubmit={handleSubmit}>
          <div className='row lgbt-row'>
            <div className='col-12'>
              <span className='lgbt-title'>Client</span>
            </div>
          </div>
          <div className='row lgbt-row lgbt-bg-grey'>
            <div className='col-3'>
              <label className='lgbt-label'>Nom</label>
              <input type='text' className='lgbt-textupd' name='lastname' value={formData.lastname} onChange={handleChange} />
            </div>
            <div className='col-3'>
              <label className='lgbt-label'>Prénom</label>
              <input type='text' className='lgbt-textupd' name='firstname' value={formData.firstname} onChange={handleChange} />
            </div>
          </div>
          <div className='row lgbt-row'>
            <div className='col-3'>
              <label className='lgbt-label'>Sexe</label>
              <input type='text' className='lgbt-textupd' name='gender' value={formData.gender} onChange={handleChange} />
            </div>
            <div className='col-3'>
              <label className='lgbt-label'>Âge</label>
              <input type='text' className='lgbt-textupd' name='age' value={formData.age} onChange={handleChange} />
            </div>
          </div>
          <div className='row lgbt-row lgbt-bg-grey'>
            <div className='col-6'>
              <label className='lgbt-label'>Adresse 1</label>
              <input type='text' className='lgbt-textupd' name='address1' value={formData.address1} onChange={handleChange} />
            </div>
            <div className='col-3'>
              <label className='lgbt-label'>CP</label>
              <input type='text' className='lgbt-textupd' name='postalcode' value={formData.postalcode} onChange={handleChange} />
            </div>
            <div className='col-3'>
              <label className='lgbt-label'>Ville</label>
              <input type='text' className='lgbt-textupd' name='city' value={formData.city} onChange={handleChange} />
            </div>
          </div>
          <div className='row lgbt-row'>
            <div className='col-6'>
              <label className='lgbt-label'>Adresse 2</label>
              <input type='text' className='lgbt-textupd' name='address2' value={formData.address2} onChange={handleChange} />
            </div>
          </div>
          <div className='row lgbt-row lgbt-bg-grey'>
            <div className='col-3'>
              <label className='lgbt-label'>Téléphone</label>
              <input type='text' className='lgbt-textupd' name='phone_number' value={formData.phone_number} onChange={handleChange} />
            </div>
            <div className='col-6'>
              <label className='lgbt-label'>E-mail</label>
              <input type='email' className='lgbt-textupd' name='email' value={formData.email} onChange={handleChange} />
            </div>
          </div>
          <div className='row lgbt-row'>
            <div className='col-12'>
              <label className='lgbt-label'>Commentaire</label>
              <textarea className='lgbt-textupd' name='comments' value={formData.comments} onChange={handleChange} />
            </div>
          </div>
          <div className='row lgbt-row'>
            <div className='col-12 d-flex justify-content-center'>
              <button className='customerpage-button'>Modifier</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CustomerEditPage;
