import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import '../scss/features/header.scss';
import { FaChevronLeft } from 'react-icons/fa';
import { CgClose } from 'react-icons/cg';

const Header = ({ headerTitle, backArrow, closeIcon }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState('/dashboard')

  const handleBackButtonClick = () => {
    navigate(prevLocation, { prevLocation: prevLocation }, null, prevLocation);
  };

  useEffect(() => {
    if (location.state && location.state.prevLocation) {
      setPrevLocation(location.state.prevLocation)
    }

  }, [])

  return (
    <div className='header-wrapper'>
      {backArrow ? <span className="header-backarrow" onClick={handleBackButtonClick}><FaChevronLeft /></span> : ''}
      <h1 className="header-title">{headerTitle}</h1>
      {closeIcon ? <span className="header-closeIcon" onClick={() => navigate('/dashboard')}><CgClose /></span> : ''}
    </div>
  )
}

export default Header