import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice"
import authReducer from '../features/auth/authSlice'
import {backgroundPositionSlice} from "../features/tools/backgroundPositionSlice"
import {apiUrlSlice} from "../features/tools/apiUrlSlice"

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    backgroundPosition: backgroundPositionSlice.reducer,
    apiUrl: apiUrlSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true
});