import { Outlet, Link } from "react-router-dom"
import { useEffect, useRef, useState } from 'react'
import { useRefreshMutation } from "./authApiSlice"
import usePersist from "../../hooks/usePersist"
import { useSelector } from 'react-redux'
import { selectAccessToken } from "./authSlice"
import jwt_decode from "jwt-decode";

const PersistLogin = () => {

  const [persist] = usePersist()
  const accessToken = useSelector(selectAccessToken)
  
  let refreshToken = null;

  if (localStorage.getItem("tokens")) {
    let tokens = JSON.parse(localStorage.getItem("tokens"));
    refreshToken = tokens.refresh;
  }

  const effectRan = useRef(false)

  const [trueSuccess, setTrueSuccess] = useState(false)

  const [refresh, {
    isUninitialized,
    isLoading,
    isSuccess,
    isError,
    error
  }] = useRefreshMutation()


  useEffect(() => {

    if (effectRan.current === true || process.env.NODE_ENV !== 'development') { // React 18 Strict Mode

      const verifyRefreshToken = async () => {
        console.log('verifying refresh token')
        console.log(refreshToken)
        try {
          await refresh(refreshToken)
          setTrueSuccess(true)
        }
        catch (err) {
          console.error(err)
        }
      }

      if (!accessToken && persist) verifyRefreshToken()
    }

    return () => effectRan.current = true

    // eslint-disable-next-line
  }, [])


  let content
  if (!persist) { // persist: no
    content = <Outlet />
    localStorage.removeItem("tokens")
    localStorage.removeItem("bgposition")
  } else if (isLoading) { //persist: yes, token: no
    content = <p>Loading...</p>
  } else if (isError) { //persist: yes, token: no
    content = <Outlet />
    localStorage.removeItem("tokens")
    localStorage.removeItem("bgposition")
  } else if (isSuccess && trueSuccess) { //persist: yes, token: yes
    content = <Outlet />
  } else if (accessToken && isUninitialized) { //persist: yes, token: yes
    content = <Outlet />
  }

  return content
}
export default PersistLogin