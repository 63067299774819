import { createSlice } from "@reduxjs/toolkit";

export const backgroundPositionSlice = createSlice({
  name: "backgroundPosition",
  initialState: localStorage.getItem('bgposition') ? localStorage.getItem('bgposition') : "-40vw",
  reducers: {
    changeBackgroundPosition: (state, action) => {
      state = action.payload;

      localStorage.setItem("bgposition",  action.payload);

      return state;
    }
  }
});

export const { changeBackgroundPosition } = backgroundPositionSlice.actions;