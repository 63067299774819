import React, { useState, useEffect } from 'react';
import '../scss/pages/processesPage.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeBackgroundPosition } from "../features/tools/backgroundPositionSlice";
import Header from '../features/Header';
import HandleClickToDashboard from '../features/tools/HandleClickToDashboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCamera, faDesktop, faMobile, faHeadphones, faLightbulb } from '@fortawesome/free-solid-svg-icons';

const ProcessesPage = () => {
  const apiUrl = useSelector(state => state.apiUrl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [text, setText] = useState([]);
  const [processes, setProcesses] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const location = useLocation();

  const prevSlug = location.state && location.state.prevLocation && location.state.prevLocation.pathname;

  //Utile pour l'effet de transition
  const [show, setShow] = useState(false);

  const handleInputChange = (text) => {
    let matches = [];

    if (text.length > 0) {
      matches = processes.filter(process => {
        const regex = new RegExp(`${text}`, "gi");
        return process.title.match(regex);
      })
    }

    setSuggestions(matches)
    setText(text);
  }

  useEffect(() => {
    const loadProcesses = () => {
      fetch(`${apiUrl}/processes/`)
        .then(response => response.json())
        .then(data => {
          setProcesses(data)
        })
        .catch(error => console.error('Erreur lors de la récupération des processes :', error));
    }

    loadProcesses();
  }, [])

  const handleCLickOnSuggest = (suggestion) => {
    navigate(`/processes/${suggestion.id}`, { state: { prevLocation: location, process: suggestion } })
  };

  const goToCategory = (category) => {
    navigate(`/category/${category}`, { state: { prevLocation: location, category: category } })
  };

  useEffect(() => {
    dispatch(changeBackgroundPosition("-65vw"));
    setShow(true);
  }, [])

  return (
    <div className='process-wrapper'>
      <HandleClickToDashboard dispatch={dispatch} navigate={navigate} setShow={setShow} />
      <div className='process-content'
        style={{
          marginLeft: show ? '0' : '100%',
          transition: prevSlug === "/dashboard" && 'all 2s'
        }}
      >
        <Header headerTitle='Liste des tutos / process' />
        <div className='process-content-search'>
          <div className='process-content-search-input'>
            <input type="text" name="search_process" autocomplete="off" onChange={e => handleInputChange(e.target.value)} placeholder="Je recherche un tuto / process sur ..." />
            <div className='process-content-search-icon'>
              <FontAwesomeIcon size="3x" icon={faSearch} />
            </div>
            <div className='process-content-search-suggestions' style={{border: suggestions.length ? '5px solid #AF2418' : 'none'}}>
              {suggestions && suggestions.map((suggestion, i) =>
                <div className='process-content-search-suggestions-option' key={i} onClick={() => handleCLickOnSuggest(suggestion)}> {suggestion.title} </div>
              )}
            </div>
          </div>
          <div className='process-content-categories'>
            <FontAwesomeIcon onClick={() => goToCategory('ordinateur')} size="4x" icon={faDesktop} />
            <FontAwesomeIcon onClick={() => goToCategory('mobile')} size="4x" icon={faMobile} />
            <FontAwesomeIcon onClick={() => goToCategory('camera')} size="4x" icon={faCamera} />
            <FontAwesomeIcon onClick={() => goToCategory('ideas')} size="4x" icon={faLightbulb} />
            <FontAwesomeIcon onClick={() => goToCategory('headphones')} size="4x" icon={faHeadphones} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProcessesPage