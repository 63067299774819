import React, {useEffect} from 'react';
import LoginForm from '../features/auth/LoginForm';
import '../scss/pages/loginpage.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { changeBackgroundPosition } from "../features/tools/backgroundPositionSlice";

function LoginPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeBackgroundPosition("-40vw"));
    }, [])

    return (
        <div className='loginpage'>
            <div className="loginpage-left">
            </div>
            <div className="loginpage-right">
                <LoginForm navigate={navigate} />
            </div>
        </div>
    );
}

export default LoginPage;
