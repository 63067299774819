import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../features/Header';
import '../scss/pages/customerPage.scss';
import { FaEdit, FaRegEdit, FaRegEye } from 'react-icons/fa';
import { FiTrash2 } from 'react-icons/fi';
import Table from "../features/DataTable";
import { changeBackgroundPosition } from '../features/tools/backgroundPositionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { CgEnter } from 'react-icons/cg';

const CustomerPage = () => {
  const apiUrl = useSelector(state => state.apiUrl);

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const [customer, setCustomer] = useState({});
  const [customertasks, setCustomerTasks] = useState({});
  const [customerDepots, setCustomerDepots] = useState({});

  const dispatch = useDispatch();

  //Utile pour l'effet de transition
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(changeBackgroundPosition("100vw"));
    setShow(true);
  }, [])

  useEffect(() => {
    const loadCustomer = () => {
      fetch(`${apiUrl}/customers/${params.id}`)
        .then(response => response.json())
        .then(data => {
          setCustomer(data)
        })
        .catch(error => console.error('Erreur lors de la récupération des données du client :', error));
    }

    loadCustomer();
  }, [])

  useEffect(() => {
    const loadTasks = () => {
      fetch(`${apiUrl}/tasks?id_customer=${params.id}`)
        .then(response => response.json())
        .then(data => {
          setCustomerTasks(data)
        })
        .catch(error => console.error('Erreur lors de la récupération des données de la tâche :', error));
    }

    loadTasks();
  }, [])

  useEffect(() => {
    const loadDepots = () => {
      fetch(`${apiUrl}/depots?id_customer=${params.id}`)
        .then(response => response.json())
        .then(data => {
          setCustomerDepots(data)
        })
        .catch(error => console.error('Erreur lors de la récupération des données du dépot :', error));
    }

    loadDepots();
  }, [])

  const handleClickUpdate = () => {
    navigate(`/customer/edit/${params.id}`, { state: { prevLocation: location } });
  }

  const handleClickDelete = () => {
    confirmAlert({
      title: '',
      message: 'Êtes-vous sûr de vouloir supprimer ce client ?',
      buttons: [
        {
          label: 'Oui',
          onClick: async () => {
            fetch(`${apiUrl}/customers/${params.id}`, {
              method: 'DELETE'
            })
              .then(response => {
                if (response.ok) {
                  toast.success(`Le client avec l'ID ${params.id} a été supprimé avec succès.`, {
                    position: toast.POSITION.TOP_RIGHT
                  });

                  navigate(`/customer-search`, { state: { prevLocation: location } });
                } else {
                  toast.error('Erreur lors de la suppression du client.', {
                    position: toast.POSITION.TOP_RIGHT
                  });
                }
              })
              .catch(() => {
                toast.error('Erreur lors de la suppression du client.', {
                  position: toast.POSITION.TOP_RIGHT
                });
              });
          }
        },
        {
          label: 'Non',
          onClick: () => { }
        }
      ]
    });
  }

  const handleClickDepot = () => {
    navigate(`/depot/create`, { state: { prevLocation: location, id_customer: `${params.id}` } });
  }

  const handleClickTask = (id_task) => {
    navigate(`/task/${id_task}`, { state: { prevLocation: location } });
  }

  const handleClickToDepot = (id_depot) => {
    navigate(`/depot/${id_depot}`, { state: { prevLocation: location } });
  }

  const handleClickUpdateDepot = (id_depot) => {
    navigate(`/depot/edit/${id_depot}`, { state: { prevLocation: location } });
  }

  const columnsTasks = [
    {
      name: "ID",
      selector: "id",
      sortable: true
    },
    {
      name: "Titre",
      selector: "title",
      sortable: true
    },
    {
      name: "Description",
      selector: "description",
      sortable: true
    },
    {
      name: "Deadline",
      selector: "deadline",
      sortable: true
    },
    {
      name: "Actions",
      button: true,
      cell: row =>
        row.showButtons ? (
          <>
            <button onClick={() => handleClickTask(row.id)}><FaRegEye /></button>
          </>
        ) : null
    }
  ];

  const columnsDepots = [
    {
      name: "ID",
      selector: "id",
      sortable: true
    },
    {
      name: "Description",
      selector: "description",
      sortable: true
    },
    {
      name: "Date de dépot",
      selector: "deposited_at",
      sortable: true
    },
    {
      name: "Date de récupération",
      selector: "collected_at",
      sortable: true
    },
    {
      name: "Actions",
      button: true,
      cell: row =>
        <>
          <button onClick={() => handleClickUpdateDepot(row.id)}><FaEdit /></button>
          <button onClick={() => handleClickToDepot(row.id)}><FaRegEye /></button>
        </>
    }
  ];

  return (
    <div>
      <Header headerTitle={'Fiche client'} backArrow={true} closeIcon={true} />
      <div className='customerpage-wrapper'>
        <div className='row lgbt-row'>
          <div className='col-12'>
            <span className='lgbt-title'>Client</span>
          </div>
        </div>
        <div className='row lgbt-row lgbt-bg-grey'>
          <div className='col-3'>
            <span className='lgbt-label'>Nom</span>
            <span className='lgbt-text'>{customer.lastname ? customer.lastname : '-'}</span>
          </div>
          <div className='col-3'>
            <span className='lgbt-label'>Prénom</span>
            <span className='lgbt-text'>{customer.firstname ? customer.firstname : '-'}</span>
          </div>
        </div>
        <div className='row lgbt-row'>
          <div className='col-3'>
            <span className='lgbt-label'>Sexe</span>
            <span className='lgbt-text'>{customer.gender ? customer.gender : '-'}</span>
          </div>
          <div className='col-3'>
            <span className='lgbt-label'>Âge</span>
            <span className='lgbt-text'>{customer.age ? customer.age : '-'}</span>
          </div>
        </div>
        <div className='row lgbt-row lgbt-bg-grey'>
          <div className='col-6'>
            <span className='lgbt-label'>Adresse 1</span>
            <span className='lgbt-text'>{customer.address1 ? customer.address1 : '-'}</span>
          </div>
          <div className='col-3'>
            <span className='lgbt-label'>CP</span>
            <span className='lgbt-text'>{customer.postalcode ? customer.postalcode : '-'}</span>
          </div>
          <div className='col-3'>
            <span className='lgbt-label'>Ville</span>
            <span className='lgbt-text'>{customer.city ? customer.city : '-'}</span>
          </div>
        </div>
        <div className='row lgbt-row'>
          <div className='col-6'>
            <span className='lgbt-label'>Adresse 2</span>
            <span className='lgbt-text'>{customer.address2 ? customer.address2 : '-'}</span>
          </div>
        </div>
        <div className='row lgbt-row lgbt-bg-grey'>
          <div className='col-3'>
            <span className='lgbt-label'>Téléphone</span>
            <span className='lgbt-text'>{customer.phone_number ? customer.phone_number : '-'}</span>
          </div>
          <div className='col-6'>
            <span className='lgbt-label'>E-mail</span>
            <span className='lgbt-text'>{customer.email ? customer.email : '-'}</span>
          </div>
        </div>
        <div className='row lgbt-row'>
          <div className='col-12'>
            <span className='lgbt-label'>Commentaire</span>
            <span className='lgbt-text'>{customer.comments ? customer.comments : '-'}</span>
          </div>
        </div>
        <div className='row lgbt-row lgbt-bg-grey justify-content-center'>
          <div className="col-1" onClick={handleClickUpdate}>
            <span className='lgbt-action-icon'><FaRegEdit /></span>
            <span className='lgbt-action-label'>Modifier</span>
          </div>
          <div className="col-1" onClick={handleClickDelete}>
            <span className='lgbt-action-icon'><FiTrash2 /></span>
            <span className='lgbt-action-label'>Supprimer</span>
          </div>
        </div>
        <div className='row lgbt-row'>
          <div className='col-12 d-flex justify-content-center'>
            <span className='customerpage-button' onClick={handleClickDepot}>Ajouter un dépôt</span>
          </div>
        </div>
        {/* Tâches */}
        {customertasks.length > 0 ?
          <>
            <div className='row lgbt-row lgbt-bg-grey'>
              <div className='col-12'>
                <span className='lgbt-title'>Tâches</span>
              </div>
            </div>
            <div className='row lgbt-row datatable-without-filter'>
              <Table data={customertasks} columns={columnsTasks} />
            </div>
          </>
          : ''
        }
        {/* Dépots */}
        {customerDepots.length > 0 ?
          <>
            <div className='row lgbt-row lgbt-bg-grey'>
              <div className='col-12'>
                <span className='lgbt-title'>Dépots</span>
              </div>
            </div>
            <div className='row lgbt-row datatable-without-filter'>
              <Table data={customerDepots} columns={columnsDepots} />
            </div>
          </>
          : ''
        }
        {/* Factures */}
        <div className='row lgbt-row lgbt-bg-grey'>
          <div className='col-12'>
            <span className='lgbt-title'>Factures</span>
          </div>
        </div>
        <div className='row lgbt-row'>
          <span style={{'textAlign':'center','fontSize': '25px', 'textTransform': 'uppercase'}}>à venir</span>
        </div>
        {/* Devis */}
        <div className='row lgbt-row lgbt-bg-grey'>
          <div className='col-12'>
            <span className='lgbt-title'>Devis</span>
          </div>
        </div>
        <div className='row lgbt-row'>
          <span style={{'textAlign':'center','fontSize': '25px', 'textTransform': 'uppercase'}}>à venir</span>
        </div>
      </div>
    </div>
  )
}

export default CustomerPage