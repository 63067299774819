import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import '../scss/pages/taskPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { changeBackgroundPosition } from "../features/tools/backgroundPositionSlice";
import HandleClickToDashboard from '../features/tools/HandleClickToDashboard';
import Header from '../features/Header';

const TaskPage = () => {
  const apiUrl = useSelector(state => state.apiUrl);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  //Utile pour l'effet de transition
  const [show, setShow] = useState(false);
  const [fetchedTask, setFetchedTask] = useState(false);
  const [fetchedQuestion, setFetchedQuestion] = useState(false);
  const [statusFriendly, setStatusFriendly] = useState('');

  const [task, setTask] = useState({});
  const [customer, setCustomer] = useState({});


  const [question, setQuestion] = useState(null);
  const [posts, setPosts] = useState({});

  const [diag, setDiag] = useState(task.id_diag);

  //Position du background
  useEffect(() => {
    dispatch(changeBackgroundPosition("80vw"));
    setShow(true);
  }, [])

  //Récupération de la tâche
  useEffect(() => {
    const loadTask = () => {
      fetch(`${apiUrl}/tasks/${params.id}`)
        .then(response => response.json())
        .then(data => {
          setTask(data)
          setFetchedTask(true)

          switch (data.status) {
            case "stopped":
              setStatusFriendly("En pause");
              break;
            case "ended":
              setStatusFriendly("Terminé");
              break;
            case "in_progress":
              setStatusFriendly("En cours");
              break;
            default:
              setStatusFriendly("À faire")
          }
        })
        .catch(error => console.error('Erreur lors de la récupération de la tâche :', error));
    }

    loadTask();
  }, [])

  const getQuestionById = (id_question) => {
    fetch(`${apiUrl}/questions/${id_question}`)
      .then(response => response.json())
      .then(data => {
        setQuestion(data);
        setFetchedQuestion(!fetchedQuestion)
      })
      .catch(error => console.error('Erreur lors de la récupération de la tâche :', error));
  }

  //Récupération du Client et de la question
  useEffect(() => {
    if (task && task.id_customer) {
      const loadCustomer = () => {
        fetch(`${apiUrl}/customers/${task.id_customer}`)
          .then(response => response.json())
          .then(data => {
            setCustomer(data)
          })
          .catch(error => console.error('Erreur lors de la récupération de la tâche :', error));
      }

      loadCustomer();
      if (null != task.id_question) {
        getQuestionById(task.id_question);
      }
    }


  }, [fetchedTask])

  //Récupération de l'article
  useEffect(() => {
    if (question && question.id) {
      const getPostsByIdQuestion = async (id_question) => {
        try {
          const response = await fetch(`${apiUrl}/questions/${id_question}`);
          const question = await response.json();
          const postIds = question.posts;
          const postPromises = postIds.map(postId => fetch(`${apiUrl}/posts/${postId}`).then(res => res.json()));
          const posts = await Promise.all(postPromises);
          setPosts(posts);
        } catch (error) {
          console.error(error);
        }
      }

      getPostsByIdQuestion(question.id);
    }


  }, [fetchedQuestion])

  const handleClickToCustomer = (id_customer) => {
    navigate(`/customer/${id_customer}`, { state: { prevLocation: location } });
  }

  const handleClickTodiag = (id_diag) => {
    navigate(`/diagnostic/${id_diag}`, { state: { prevLocation: location } });
  }

  const handleClickYes = (question) => {
    if (question.id == 1) {
      setQuestion(getQuestionById(2))

      setTask((prevState) => {
        const updatedTask = { ...prevState, 'id_question': 2 };
        fetch(`${apiUrl}/tasks/${updatedTask.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedTask)
        });
        return updatedTask;
      });
    }

    if (question.id == 2) {
      let diags = {
        id_depot: 1,
        questions: [
          1,
          2
        ],
        answers: [
          1,
          1,
        ],
        prestations: []
      };

      fetch(`${apiUrl}/diags`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(diags)
      }).then(response => response.json())
        .then(data => {
          const { id } = data;

          setDiag(id)

          setTask((prevState) => {
            const updatedTask = { ...prevState, 'id_question': null, 'status': 'terminé', 'id_diag': id };
            fetch(`${apiUrl}/tasks/${updatedTask.id}`, {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(updatedTask)
            });
            return updatedTask;
          });
        })


      setPosts(null);
      setQuestion(null);
    }

    if (question.id == 3) {
      setQuestion(getQuestionById(4))

      setTask((prevState) => {
        const updatedTask = { ...prevState, 'id_question': 4 };
        fetch(`${apiUrl}/tasks/${updatedTask.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedTask)
        });
        return updatedTask;
      });
    }

    if (question.id == 4) {
      let diags = {
        id_depot: 1,
        questions: [
          1,
          3,
          4
        ],
        answers: [
          0,
          1,
          1
        ],
        prestations: []
      };

      fetch(`${apiUrl}/diags`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(diags)
      }).then(response => response.json())
        .then(data => {
          const { id } = data;

          setDiag(id)

          setTask((prevState) => {
            const updatedTask = { ...prevState, 'id_question': null, 'status': 'terminé', 'id_diag': id };
            fetch(`${apiUrl}/tasks/${updatedTask.id}`, {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(updatedTask)
            });
            return updatedTask;
          });
        })


      setPosts(null);
      setQuestion(null);
    }
  }

  const handleClickNo = (question) => {
    if (question.id == 1) {
      setQuestion(getQuestionById(3))

      setTask((prevState) => {
        const updatedTask = { ...prevState, 'id_question': 3 };
        fetch(`${apiUrl}/tasks/${updatedTask.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedTask)
        });
        return updatedTask;
      });
    }

    if (question.id == 2) {
      let diags = {
        id_depot: 1,
        questions: [
          1,
          2
        ],
        answers: [
          1,
          1,
        ],
        prestations: []
      };

      fetch(`${apiUrl}/diags`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(diags)
      }).then(response => response.json())
        .then(data => {
          const { id } = data;

          setDiag(id)

          setTask((prevState) => {
            const updatedTask = { ...prevState, 'id_question': null, 'status': 'terminé', 'id_diag': id };
            fetch(`${apiUrl}/tasks/${updatedTask.id}`, {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(updatedTask)
            });
            return updatedTask;
          });
        })


      setPosts(null);
      setQuestion(null);
    }

    if (question.id == 3) {
      let diags = {
        id_depot: 1,
        questions: [
          1,
          2,
          3
        ],
        answers: [
          0,
          1,
          0
        ],
        prestations: []
      };

      fetch(`${apiUrl}/diags`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(diags)
      }).then(response => response.json())
        .then(data => {
          const { id } = data;

          setDiag(id)

          setTask((prevState) => {
            const updatedTask = { ...prevState, 'id_question': null, 'status': 'terminé', 'id_diag': id };
            fetch(`${apiUrl}/tasks/${updatedTask.id}`, {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(updatedTask)
            });
            return updatedTask;
          });
        })


      setPosts(null);
      setQuestion(null);
    }

    if (question.id == 4) {
      let diags = {
        id_depot: 1,
        questions: [
          1,
          3,
          4
        ],
        answers: [
          0,
          1,
          0
        ],
        prestations: []
      };

      fetch(`${apiUrl}/diags`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(diags)
      }).then(response => response.json())
        .then(data => {
          const { id } = data;

          setDiag(id)

          setTask((prevState) => {
            const updatedTask = { ...prevState, 'id_question': null, 'status': 'terminé', 'id_diag': id };
            fetch(`${apiUrl}/tasks/${updatedTask.id}`, {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(updatedTask)
            });
            return updatedTask;
          });
        })


      setPosts(null);
      setQuestion(null);
    }
  }

  return (
    <div className='taskpage-wrapper'>
      <div className='taskContent' style={{ marginLeft: show ? '0' : '-80%' }}>
        <Header headerTitle={task.title ? task.title : '-'} backArrow={true} />
        <div className='row'>
          <div className='col-8'>
            <div className='row lgbt-row'></div>
            <div className='row lgbt-row lgbt-bg-grey'>
              <div className='col-6'>
                <span className='lgbt-label'>Type</span>
                <span className='lgbt-text'>{task.type ? task.type : '-'}</span>
              </div>
              <div className='col-6'>
                <span className='lgbt-label'>Date limite</span>
                <span className='lgbt-text'>{task.deadline ? task.deadline : '-'}</span>
              </div>
            </div>
            <div className='row lgbt-row'>
              <div className='col-6'>
                <span className='lgbt-label'>Description</span>
                <span className='lgbt-text'>{task.description ? task.description : '-'}</span>
              </div>
              <div className='col-6'>
                <span className='lgbt-label'>Statut</span>
                <span className='lgbt-text'>{statusFriendly}</span>
              </div>
            </div>
            <div className='row lgbt-row lgbt-bg-grey'>
              <div className='col-12'>
                <span className='lgbt-label'>Commentaire</span>
                <span className='lgbt-text'>{task.comment ? task.comment : '-'}</span>
              </div>
            </div>
          </div>
          <div className='col-4 task-customercard'>
            <span className='task-customercard-title'>{(customer.gender == "m" || customer.gender == "M") ? 'M.' : 'MME'} {customer.lastname ? customer.lastname : ''} {customer.firstname ? customer.firstname : ''}</span>
            {customer.phone_number && <span className='task-customercard-label'>{customer.phone_number}</span>}
            {customer.email && <span className='task-customercard-label'>{customer.email}</span>}
            <span onClick={() => handleClickToCustomer(customer.id)} className='task-customercard-label'>Voir la fiche client</span>
          </div>
        </div>
        <div className='row lgbt-row'>
        </div>

        <div className='row'>
          <div className='col-5 offset-1'>
            <div className='task-left-card'>
              <div className='task-left-card-header'>
                <span>{task.type == 'diag' ? 'Diagnostic' : 'Prestation'}</span>
              </div>
              <div className='task-left-card-body'>
                {task.type == 'diag' && question ?
                  <>
                    <div className='task-left-card-body-question'>{question.title}</div>
                    <div className='task-left-card-body-buttons'>
                      <button className='task-left-card-body-button' onClick={() => handleClickYes(question)}>OUI</button>
                      <button className='task-left-card-body-button' onClick={() => handleClickNo(question)}>NON</button>
                    </div>
                  </>
                  :
                  task.type == 'diag' ?
                    <>
                      <div className='task-left-card-body-question'>Diagnostic terminé</div>
                      {task.id_diag && <div className='task-left-card-body-button' onClick={() => handleClickTodiag(task.id_diag)}>Voir le diagnostic</div>}
                    </>
                    : ''


                }
              </div>
            </div>
          </div>
          {question &&
            <div className='col-5'>
              <div className='task-right-card'>
                <div className='task-right-card-header'>
                  <span>Liste des articles / process</span>
                </div>
                <div className='task-right-card-body'>
                  {(posts && posts.length > 0) ? posts.map((post, i) =>
                    <div className='task-right-card-body-post' key={i}>
                      <h2 className='task-right-card-body-post-title'>{post.title}</h2>
                      <img className='task-right-card-body-post-image' src={post.image} />
                      <p className='task-right-card-body-post-text'>{post.content}</p>
                    </div>
                  ) :
                    <div className='text-center'>Aucun article lié</div>
                  }
                </div>
              </div>
            </div>
          }
        </div>

        <div className='row lgbt-row'>
        </div>
      </div>
      <HandleClickToDashboard dispatch={dispatch} navigate={navigate} location={location} setShow={setShow} />
    </div>
  )
}

export default TaskPage