import { apiSlice } from "../../app/api/apiSlice";
import { logOut, setCredentials } from "./authSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: credentials => ({
        url: '/token/',
        method: 'POST',
        body: { ...credentials }
      })
    }),
    refresh: builder.mutation({
      query: refresh => ({
        url: '/token/refresh/',
        method: 'POST',
        body: { refresh }
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const { access } = data

          dispatch(setCredentials({ ...data, access }))
        } catch (err) {
          console.log(err)
        }
      }
    }),
  })
})

export const {
  useLoginMutation,
  useRefreshMutation
} = authApiSlice