import Dashboard from './pages/DashboardPage';
import { Routes, Route } from 'react-router-dom';
import BackgroundTruck from './features/BackgroundTruck'
import LoginPage from './pages/LoginPage';
import RequireAuth from './features/auth/RequireAuth';
import PersistLogin from './features/auth/PersistLogin';
import TasksListPage from './pages/TasksListPage';
import TaskPage from './pages/TaskPage';
import CustomerSearchPage from './pages/CustomerSearchPage';
import CustomerPage from './pages/CustomerPage';
import Processes from './pages/Processes';
import ProcessesPage from './pages/ProcessesPage';
import CategoryPage from './pages/CategoryPage';
import CustomerCreatePage from './pages/CustomerCreatePage';
import CustomerEditPage from './pages/CustomerEditPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import DepotCreatePage from './pages/DepotCreatePage';
import DiagnosticPage from './pages/DiagnosticPage';
import DepotPage from './pages/DepotPage';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setApiUrl } from './features/tools/apiUrlSlice';
import DepotEditPage from './pages/DepotEditPage';

function App() {
  const dispatch = useDispatch();
  let apiUrl;

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      apiUrl = process.env.REACT_APP_DEV_API_URL;
    } else {
      apiUrl = process.env.REACT_APP_PROD_API_URL;
    }

    dispatch(setApiUrl(apiUrl));
  }, [apiUrl]);

  return (
    <>
      <ToastContainer />
      <BackgroundTruck>
        <Routes>
          {/* public routes */}
          <Route path="/" element={<LoginPage />} />

          {/* protected routes */} {/* EN ATTENTE API */}
          <Route element={<PersistLogin />}>
            {/* <Route element={<RequireAuth />}> */}
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/tasks" element={<TasksListPage />} />
              <Route path='/task/:id'
                exact
                element={<TaskPage />}
              />
              <Route path="/customer-search" element={<CustomerSearchPage />} />
              <Route path="/customer/:id" element={<CustomerPage />} />
              <Route path="/customer/create" element={<CustomerCreatePage />} />
              <Route path="/customer/edit/:id" element={<CustomerEditPage />} />
              <Route path="/processes" element={<Processes />} />
              <Route path="/processes/:id" element={<ProcessesPage />} />
              <Route path="/category/:category" element={<CategoryPage />} />
              <Route path="/diagnostic/:id" element={<DiagnosticPage />} />
              <Route path="/depot/create" element={<DepotCreatePage />} />
              <Route path="/depot/edit/:id" element={<DepotEditPage />} />
              <Route path="/depot/:id" element={<DepotPage />} />
            {/* </Route> */} {/* EN ATTENTE API */}
          </Route>
        </Routes>
      </BackgroundTruck>
    </>
  )
}

export default App;