import { changeBackgroundPosition } from "./backgroundPositionSlice";
import { useCallback } from 'react';

const HandleClickToDashboard = ({dispatch, navigate, location, setShow}) => {
  const handleClick = useCallback(() => {
      setShow(false);
      dispatch(changeBackgroundPosition("50%"));
      setTimeout(() => {
          navigate('/dashboard', { state: { prevLocation: location } })
      }, 2000);
  }, [dispatch, navigate, location, setShow]);

  return <span className="linkDashboard" onClick={handleClick}></span>
}

export default HandleClickToDashboard;