import { createSlice } from "@reduxjs/toolkit";

export const apiUrlSlice = createSlice({
  name: "api",
  initialState: "",
  reducers: {
    setApiUrl: (state, action) => {
      state = action.payload;

      return state;
    }
  }
});

export const { setApiUrl } = apiUrlSlice.actions;