import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import '../scss/pages/diagnosticPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { changeBackgroundPosition } from "../features/tools/backgroundPositionSlice";
import Header from '../features/Header';
import '../scss/features/dataTable.scss';
import { FaEye, FaRegEye } from 'react-icons/fa';
import Table from '../features/DataTable';

const DiagnosticPage = () => {
  const apiUrl = useSelector(state => state.apiUrl);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  //Utile pour l'effet de transition
  const [show, setShow] = useState(false);
  const [diagnostic, setDiagnostic] = useState({});
  const [depot, setDepot] = useState({});
  const [customer, setCustomer] = useState({});
  const [questions, setQuestions] = useState({});
  const [prestations, setPrestations] = useState({});
  const [friendlyDepositedAt, setFriendlyDepositedAt] = useState();

  //Position du background
  useEffect(() => {
    dispatch(changeBackgroundPosition("100vw"));
    setShow(true);
  }, [])

  //Récupération du diagnostic
  useEffect(() => {
    const loadDiagnostic = () => {
      fetch(`${apiUrl}/diags/${params.id}`)
        .then(response => response.json())
        .then(data => {
          setDiagnostic(data)
        })
        .catch(error => console.error('Erreur lors de la récupération du diagnostic :', error));
    }

    loadDiagnostic();
  }, [])

  //Récupération du client
  useEffect(() => {
    if (depot && depot.id) {
      const loadCustomer = () => {
        fetch(`${apiUrl}/customers?id=${depot.id_customer}`)
          .then(response => response.json())
          .then(data => {
            setCustomer(data[0])
          })
          .catch(error => console.error('Erreur lors de la récupération du client :', error));
      }

      loadCustomer();

      /* Affichage de la date */
      let date = new Date(depot.deposited_at);
      let options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
      };

      setFriendlyDepositedAt(date.toLocaleString("fr-FR", options));
    }
  }, [depot])

  //Récupération des questions et  des prestations et du dépot associée
  useEffect(() => {
    if (diagnostic && diagnostic.id) {
      const getPostsByIdQuestion = async (id_diag) => {
        try {
          const response = await fetch(`${apiUrl}/diags/${id_diag}`);
          const diagnostic = await response.json();
          const questionsIds = diagnostic.questions;
          const questionPromises = questionsIds.map(questionId => fetch(`${apiUrl}/questions/${questionId}`).then(res => res.json()));
          const questionsResponse = await Promise.all(questionPromises);
          setQuestions(questionsResponse);
        } catch (error) {
          console.error(error);
        }
      }

      getPostsByIdQuestion(diagnostic.id);

      const getPrestationsByDiagnostic = async (id_diag) => {
        try {
          const response = await fetch(`${apiUrl}/diags/${id_diag}`);
          const diagnostic = await response.json();
          const prestations = diagnostic.prestations;
          const prestationPromises = prestations.map(prestationId => fetch(`${apiUrl}/prestations/${prestationId}`).then(res => res.json()));
          const prestationsResponse = await Promise.all(prestationPromises);
          setPrestations(prestationsResponse);
        } catch (error) {
          console.error(error);
        }
      }

      getPrestationsByDiagnostic(diagnostic.id);

      const loadDepot = () => {
        fetch(`${apiUrl}/depots?id_depot=${diagnostic.id_depot}`)
          .then(response => response.json())
          .then(data => {
            setDepot(data[0])
          })
          .catch(error => console.error('Erreur lors de la récupération du dépot :', error));
      }

      loadDepot();
    }

  }, [diagnostic])

  const handleClickToCustomer = (id_customer) => {
    navigate(`/customer/${id_customer}`, { state: { prevLocation: location } });
  }

  const handleClickToPrestation = (id_prestation) => {
    navigate(`/prestation/${id_prestation}`, { state: { prevLocation: location } });
  }

  const columnsPrestation = [
    {
      name: "ID",
      selector: "id",
      sortable: true
    },
    {
      name: "Titre",
      selector: "title",
      sortable: true
    },
    {
      name: "Catégorie",
      selector: "category",
      sortable: true
    },
    {
      name: "Prix de la prestation",
      selector: "price_ttc",
      sortable: true
    }
  ];

  return (
    <>
      <div className='diagnostic'>
        <Header headerTitle='Diagnostic' backArrow={true} />
        <div className='diagnostic-content'>
          <div className='row'>
            {depot ?
              <div className='col-8'>
                <div className='row lgbt-row'>
                  <div className='col-12'>
                    <span className='lgbt-label diagnostic-mini-title'>Dépot lié</span>
                  </div>
                </div>
                <div className='row lgbt-row lgbt-bg-grey align-items-start'>
                  <div className='col-6'>
                    <label className='lgbt-label'>Date de réception du matériel</label><br /><br />
                    <span className='lgbt-text'>
                      {friendlyDepositedAt ? friendlyDepositedAt : '-'}
                    </span>
                  </div>
                  <div className='col-6'>
                    <span className='lgbt-label'>Informations </span><br /><br />
                    <span className='lgbt-longtext' dangerouslySetInnerHTML={{ __html: depot.description && depot.description.replace(/\n/g, '<br />') }} />
                  </div>
                </div>
              </div>
              : ''}
            <div className='col-4 task-customercard'>
              <span className='task-customercard-title'>{(customer.gender == "m" || customer.gender == "M") ? 'M.' : 'MME'} {customer.lastname ? customer.lastname : ''} {customer.firstname ? customer.firstname : ''}</span>
              {customer.phone_number && <span className='task-customercard-label'>{customer.phone_number}</span>}
              {customer.email && <span className='task-customercard-label'>{customer.email}</span>}
              <span onClick={() => handleClickToCustomer(customer.id)} className='task-customercard-label'>Voir la fiche client</span>
            </div>
          </div>

          <div className='row lgbt-row'>
          </div>

          <div className='row lgbt-row lgbt-bg-grey'>
            <div className='col-12'>
              <span className='lgbt-title'>Diagnostic</span>
            </div>
          </div>
          <div className='row diagnostic-questions'>
            {(questions && questions.length > 0) ? questions.map((question, i) =>
              <div key={i} className='col-12 diagnostic-questions-row'>
                {(question.title ?
                  <>
                    <span className='diagnostic-question'>{question.title} </span>
                    <span className='diagnostic-answer'>{diagnostic.answers[i] ? 'OUI' : 'NON'}</span>
                  </>
                  : '')}
              </div>
            ) :
              <div className='text-center'>Aucune question trouvée</div>
            }
          </div>

          <div className='row lgbt-row'>
          </div>

          {/* Tâches */}
          {(prestations && prestations.length > 0) ?
          <>
            <div className='row lgbt-row lgbt-bg-grey'>
              <div className='col-12'>
                <span className='lgbt-title'>Prestations proposées</span>
              </div>
            </div>
            <div className='row lgbt-row datatable-without-filter'>
              <Table data={prestations} columns={columnsPrestation} />
            </div>
          </>
          : ''
        
            // <div className='row lgbt-row'>
            //   <div className='col-12'>
            //     <span className='lgbt-label diagnostic-mini-title'>Prestations proposées</span>
            //   </div>
            //   <div className='col-12'>
            //     <table className='diagnostic-table-prestation'>
            //       <thead>
            //         <tr>
            //           <th className='lgbt-label' colSpan="2">Titre</th>
            //           <th className='lgbt-label'>Description</th>
            //           <th colSpan="2"></th>
            //         </tr>
            //       </thead>
            //       <tbody className='diagnostic-table-prestation-body'>
            //         {(prestations && prestations.length > 0) ? prestations.map((prestation, i) =>
            //           <tr key={i}>
            //             <td>{prestation.title}</td>
            //             <td>{prestation.description}</td>
            //             <td><FaEye
            //             // onClick={() => navigate(`/prestation/${prestation.id}`)} 
            //             /></td>
            //           </tr>
            //         ) :
            //           <tr>
            //             <td>Aucune prestation trouvée</td>
            //           </tr>
            //         }
            //       </tbody>
            //     </table>
            //   </div>
            // </div>
          }
        </div>
      </div>
    </>
  )
}

export default DiagnosticPage