import React, { useState, useEffect } from 'react';
import Header from '../features/Header';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import '../scss/pages/processesPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import HandleClickToDashboard from '../features/tools/HandleClickToDashboard';

const ProcessesPage = () => {
  const apiUrl = useSelector(state => state.apiUrl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { id } = useParams();

  const location = useLocation();
  const [process, setProcess] = useState([]);
  const [posts, setPosts] = useState([]);
  const [fetchedData, setFetchedData] = useState(false);

  useEffect(() => {
    const loadProcess = () => {
      fetch(`${apiUrl}/processes/${id}`)
        .then(response => response.json())
        .then(data => {
          setProcess(data)
        })
        .catch(error => console.error('Erreur lors de la récupération d\'un processe :', error));
    }

    loadProcess()
  }, [])

  //Récupération des posts
  useEffect(() => {
    if (process && process.id) {
      const getPostsByIdQuestion = async (id_question) => {
        try {
          const response = await fetch(`${apiUrl}/processes/${process.id}`);
          const processes = await response.json();
          const postIds = processes.posts;
          const postPromises = postIds.map(postId => fetch(`${apiUrl}/posts/${postId}`).then(res => res.json()));
          const posts = await Promise.all(postPromises);
          setPosts(posts);
        } catch (error) {
          console.error(error);
        }
      }

      getPostsByIdQuestion(process.id);

    }
  }, [process])

  return (
    <div className='process-wrapper'>
      {/* <HandleClickToDashboard dispatch={dispatch} navigate={navigate} setShow={setShow} /> */}
      <div style={{
          width: '20%'
        }}></div>

      <div className='process-unique'>
        <Header headerTitle={process.title} backArrow={true} closeIcon={true}/>

        <div className='process-unique-content'>
          {posts.map((post, i) =>
            <div key={i} className='process-unique-content-post'>
              {post.title ? <h2 className='process-unique-content-post-title'> {post.title} </h2> : ''}
              {post.image ? <img className='process-unique-content-post-image' src={post.image} /> : ''}
              {post.content ? <p className='process-unique-content-post-content'>{post.content}</p> : ''}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProcessesPage
