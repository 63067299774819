import React, { useEffect, useState } from 'react';
import Header from '../features/Header';
import '../scss/pages/customerSearchPage.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowRight, FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { changeBackgroundPosition } from '../features/tools/backgroundPositionSlice';

const CustomerSearchPage = () => {
  const apiUrl = useSelector(state => state.apiUrl);
  const [text, setText] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  //Utile pour l'effet de transition
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(changeBackgroundPosition("100vw"));
    setShow(true);
  }, [])

  useEffect(() => {
    const loadCustomers = () => {
      fetch(`${apiUrl}/customers`)
        .then(response => response.json())
        .then(data => {
          setCustomers(data)
        })
        .catch(error => console.error('Erreur lors de la récupération des données du client :', error));
    }

    loadCustomers();
  }, [])

  const handleInputChange = (text) => {
    let matches = [];

    if (text.length > 0) {
      matches = customers.filter(customer => {
        const regex = new RegExp(`${text}`, "gi");
        return customer.email.match(regex) || customer.firstname.match(regex) || customer.lastname.match(regex);
      })
    }
    setSuggestions(matches)
    setText(text);
  }

  const handleCLickOnSuggest = (id) => {
    navigate(`/customer/${id}`, { state: { prevLocation: location } })
  };

  const handleClickNewCustomer = () => {
    navigate('/customer/create/', { state: { prevLocation: location } })
  }

  return (
    <div>
      <Header headerTitle='Répertoire clients' closeIcon={true} />
      <div className='customersearchpage-wrapper'>
        <div className='customersearchpage-inputs'>
          <input className="customersearchpage-inputtext" type="text" onChange={e => handleInputChange(e.target.value)} value={text} placeholder="NOM / PRÉNOM / EMAIL" />
          <button className="customersearchpage-inputsubmit">
            <FaArrowRight />
          </button>
          <div className='customersearchpage-suggestions' style={{border: (suggestions.length || text.length) ? '5px solid #AF2418' : 'none'}}>
            {suggestions && suggestions.length > 0 ? suggestions.map((suggestion, i) =>
              <div className='customersearchpage-suggestions-row' key={i} onClick={() => handleCLickOnSuggest(suggestion.id)}> {suggestion.firstname}  {suggestion.lastname} -  {suggestion.phone_number} </div>
              ) :
              text.length > 0 && <div className='customersearchpage-suggestions-row'>Aucun résultat</div>
            }
          </div>
        </div>
        <span className='customersearchpage-newCustomer' onClick={handleClickNewCustomer}><FaPlus /> Ajouter un nouveau client</span>
      </div>
    </div>
  )
}

export default CustomerSearchPage